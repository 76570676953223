<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="11">
        <v-card elevation="10" class="darkGlass">
          <v-toolbar class="primaryGradient" dark flat tile dense height="auto">
            <v-toolbar-title class="titulo" style="white-space: normal">
              Bienvenido/a
              <span v-if="user">
                {{ conv.capitalizeString(user.nombre) }}
              </span>
            </v-toolbar-title>
          </v-toolbar>
          <v-alert
            v-if="userLogged.b_castigado"
            outlined
            type="warning"
            dense
            prominent
            class="mb-0"
          >
            {{ userLogged.msg_castigado }}
          </v-alert>
          <v-toolbar
            color="secondary"
            :class="[userLogged.b_castigado ? 'mt-0 pt-0' : 'mt-4']"
            dark
            flat
            tile
            height="40px"
          >
            <v-icon left>mdi-account-details</v-icon>
            <v-toolbar-title class="cabecera"
              >Información Personal</v-toolbar-title
            >
          </v-toolbar>

          <v-row
            align-content="center"
            justify="center"
            v-if="loading"
            class="my-1"
          >
            <v-col cols="12">
              <cargando-app text="Cargando Datos..." />
            </v-col>
          </v-row>
          <div v-else>
            <v-list color="transparent" class="item-text">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary"> mdi-map-marker </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-subtitle>Dirección</v-list-item-subtitle>
                  <v-list-item-title
                    v-if="
                      user.direccion_particular &&
                      user.direccion_particular.trim().length > 0
                    "
                  >
                    {{ conv.capitalizeString(user.direccion_particular) }}
                  </v-list-item-title>
                  <v-list-item-title v-else>No hay registro</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary"> mdi-cellphone </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-subtitle>Móvil</v-list-item-subtitle>
                  <v-list-item-title
                    v-if="user.Movil && parseInt(user.Movil.trim()) > 0"
                    >{{ user.Movil }}</v-list-item-title
                  >
                  <v-list-item-title v-else>No hay registro</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- <template v-if="sameEmail">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Correo Personal</v-list-item-subtitle
                    >
                    <v-list-item-title>
                      {{ user.email_personal.toLowerCase().trim() }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template> -->
              <!-- <template v-else> -->
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="primary"> mdi-email </v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-subtitle>Correo Personal</v-list-item-subtitle>
                  <v-list-item-title
                    v-if="func.validateEmail(user.email_personal)"
                    >{{
                      user.email_personal.toLowerCase().trim()
                    }}</v-list-item-title
                  >
                  <v-list-item-title v-else>No hay registro</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="primary"> mdi-email-newsletter </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Correo de Notificaciones</v-list-item-subtitle
                    >
                    <v-list-item-title
                      v-if="func.validateEmail(user.email_recado)"
                      >{{
                        user.email_recado.toLowerCase().trim()
                      }}</v-list-item-title
                    >
                    <v-list-item-title v-else
                      >No hay registro</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item> -->
              <!-- </template> -->
            </v-list>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="11">
        <v-row justify="center" align="stretch">
          <v-col v-for="product in products" :key="product.type">
            <v-card
              height="100px"
              elevation="10"
              class="darkGlass hover-animation"
              :to="{ name: 'productos', params: { product: product.to } }"
            >
              <v-toolbar color="secondary" dark flat tile dense height="36px">
                <v-icon left>{{ product.icon }}</v-icon>
                <v-toolbar-title class="cabecera">{{
                  product.type
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-title class="productos pt-2 pb-0">
                <p class="flex text-center">
                  {{
                    product.status
                      ? conv.formatMonto(product.amount, true)
                      : "No posee"
                  }}
                </p>
              </v-card-title>
              <v-card-subtitle
                class="flex text-center"
                v-if="product.status && product.type === 'Créditos'"
              >
                Total Adeudado
              </v-card-subtitle>
              <v-card-subtitle
                class="flex text-center"
                v-else-if="
                  product.status &&
                  product.type === 'Cuenta capital' &&
                  user.monto_prelacion > 0
                "
              >
                Lista Prelación
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="!publicidadesSlider" class="px-12">
        <skeleton-publicidades />
      </v-col>
      <v-col
        cols="12"
        class="px-12"
        v-if="publicidadesSlider && publicidadesSlider.length > 0"
      >
        <publicidades-fijas />
      </v-col>
    </v-row>
    <mora-dialog v-if="dialogoMora" />
  </v-container>
</template>
<script>
import auth from "@/auth/auth";
import conv from "@/services/conversores";
import funciones from "@/services/funciones";
import MoraDialog from "@/components/inicio/MoraDialog.vue";
import { mapActions, mapState } from "vuex";
import SkeletonPublicidades from "@/components/skeletons/SkeletonPublicidades.vue";
import PublicidadesFijas from "@/components/inicio/PublicidadesFijas.vue";
import CargandoApp from "@/components/app/CargandoApp.vue";

export default {
  metaInfo: { title: "Inicio" },
  components: {
    "mora-dialog": MoraDialog,
    SkeletonPublicidades,
    PublicidadesFijas,
    CargandoApp
  },
  data: function () {
    return {
      user: null,
      metaInfo: { title: "Inicio" },
      products: [
        {
          type: "Créditos",
          status: false,
          amount: 0,
          icon: "mdi-credit-card",
          to: "creditos"
        },
        {
          type: "Depósitos a plazo",
          status: false,
          amount: 0,
          icon: "mdi-bank-transfer-in",
          to: "dap"
        },
        {
          type: "Cuenta de ahorro",
          status: false,
          amount: 0,
          icon: "mdi-account-cash",
          to: "cuenta-ahorro"
        },
        {
          type: "Cuenta capital",
          status: false,
          amount: 0,
          icon: "mdi-cash",
          to: "cuenta-capital"
        }
      ],
      loading: true
    };
  },
  methods: {
    ...mapActions(["setPublicidades"]),
    async setProductos() {
      if (this.user.creditos > 0) {
        this.products[0].status = true;
        this.products[0].amount = this.user.saldo_total_credito;
      }

      if (this.user.dap > 0) {
        this.products[1].status = true;
        this.products[1].amount = this.user.monto_dap;
      }

      if (this.user.libretas > 0) {
        this.products[2].status = true;
        this.products[2].amount = this.user.monto_libreta;
      }
      if (this.user.cuenta_capital > 0) {
        this.products[3].status = true;
        this.products[3].amount = this.user.monto_capital;
      }
    },
    async getUserInfo() {
      await auth
        .userInfo(this.userLogged.id_cliente)
        .then((res) => {
          this.user = res.data;
  
          // console.log(this.user)
          this.setProductos();
          let dataUser = {
            tipo: this.user.b_empresa || 0,
            creditos: this.user.monto_creditos || 0,
            capital: this.user.monto_capital || 0,
            dap: this.user.monto_dap || 0,
            libretas: this.user.monto_libreta || 0
          };
          this.setPublicidades({ user: dataUser, mostrar: false });
        })
        .catch((error) => console.error(error));
    }
  },

  computed: {
    ...mapState([
      "dialogoMora",
      "dialogoCampania",
      "publicidadesSlider",
      "dialogoPublicidades"
    ]),
    userLogged() {
      return auth.getUserLogged();
    },
    conv() {
      return conv;
    },
    func() {
      return funciones;
    },
    sameEmail() {
      let personal = this.user.email_personal;
      let notificaciones = this.user.email_recado;
      return (
        funciones.validateEmail(personal) &&
        funciones.validateEmail(notificaciones) &&
        personal === notificaciones
      );
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 115;
        case "sm":
          return 100;
        case "md":
          return 70;
        case "lg":
          return 70;
        case "xl":
          return 70;
        default:
          return 70;
      }
    }
  },
  async mounted() {
    if (process.env.NODE_ENV !== "production") 
    this.loading = true;
    await this.getUserInfo();
    this.loading = false;
  }
};
</script>

<style scoped lang="css">
.cabecera {
  color: white;
  font-weight: 400;
  font-size: 2.5vh;
  line-height: 3vh;
}
.productos {
  color: black;
  font-weight: 300;
  font-size: 2.5vh;
  line-height: 3vh;
}
.item-text .v-list-item__title {
  white-space: normal !important;
}
</style>
