import axios from "axios";

const URL_CONTENIDO = process.env.VUE_APP_API_URL + "web/content/";

export default {
  obtenerInformativos() {
    return axios.get(URL_CONTENIDO + `getInformativesPortal`);
  },
  obtenerPublicidades(params, dialogo) {
    let visibilidad = 0; //Silder
    if (dialogo) visibilidad = 1; //Dialogo
    return axios.get(
      URL_CONTENIDO +
        `getAdvertisingPortal/${visibilidad}/${params.tipo}/${params.capital}/${params.creditos}/${params.dap}/${params.libretas}`
    );
  },
  obtenerCategorias(tipo) {
    return axios.get(URL_CONTENIDO + `getFilesCategories/${tipo}`);
  },
  obtenerDocumentos(categoria) {
    return axios.get(
      URL_CONTENIDO +
        `getFileRefsByIdNameByCategory/revistas_portal/${categoria}`
    );
  },
  obtenerModoRevista(id) {
    return axios.get(URL_CONTENIDO + `getFileToViewer/${id}`);
  },
  obtenerDataArchivo(id) {
    return axios.get(URL_CONTENIDO + `getFileData/${id}`);
  },
  obtenerSecciones() {
    return axios.get(URL_CONTENIDO + `getSectionsByType/portal`);
  },
  obtenerTextoByIdName(id_name) {
    return axios.get(URL_CONTENIDO + `getTextByIdName/${id_name}`);
  },
  obtenerTextoByGroup(group) {
    return axios.get(URL_CONTENIDO + `getTextByGroup/${group}`);
  },
  obtenerParametros() {
    return axios.get(URL_CONTENIDO + `getParamsPortal`);
  },
  obtenerOficinas() {
    return axios.get(URL_CONTENIDO + `getOffices/`);
  }
};
