var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-card',{staticClass:"darkGlass",attrs:{"elevation":"10"}},[_c('v-toolbar',{staticClass:"primaryGradient",attrs:{"dark":"","flat":"","tile":"","dense":"","height":"auto"}},[_c('v-toolbar-title',{staticClass:"titulo",staticStyle:{"white-space":"normal"}},[_vm._v(" Bienvenido/a "),(_vm.user)?_c('span',[_vm._v(" "+_vm._s(_vm.conv.capitalizeString(_vm.user.nombre))+" ")]):_vm._e()])],1),(_vm.userLogged.b_castigado)?_c('v-alert',{staticClass:"mb-0",attrs:{"outlined":"","type":"warning","dense":"","prominent":""}},[_vm._v(" "+_vm._s(_vm.userLogged.msg_castigado)+" ")]):_vm._e(),_c('v-toolbar',{class:[_vm.userLogged.b_castigado ? 'mt-0 pt-0' : 'mt-4'],attrs:{"color":"secondary","dark":"","flat":"","tile":"","height":"40px"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-details")]),_c('v-toolbar-title',{staticClass:"cabecera"},[_vm._v("Información Personal")])],1),(_vm.loading)?_c('v-row',{staticClass:"my-1",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('cargando-app',{attrs:{"text":"Cargando Datos..."}})],1)],1):_c('div',[_c('v-list',{staticClass:"item-text",attrs:{"color":"transparent"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-map-marker ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Dirección")]),(
                    _vm.user.direccion_particular &&
                    _vm.user.direccion_particular.trim().length > 0
                  )?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.conv.capitalizeString(_vm.user.direccion_particular))+" ")]):_c('v-list-item-title',[_vm._v("No hay registro")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-cellphone ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Móvil")]),(_vm.user.Movil && parseInt(_vm.user.Movil.trim()) > 0)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.Movil))]):_c('v-list-item-title',[_vm._v("No hay registro")])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-email ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Correo Personal")]),(_vm.func.validateEmail(_vm.user.email_personal))?_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.email_personal.toLowerCase().trim()))]):_c('v-list-item-title',[_vm._v("No hay registro")])],1)],1)],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',{attrs:{"justify":"center","align":"stretch"}},_vm._l((_vm.products),function(product){return _c('v-col',{key:product.type},[_c('v-card',{staticClass:"darkGlass hover-animation",attrs:{"height":"100px","elevation":"10","to":{ name: 'productos', params: { product: product.to } }}},[_c('v-toolbar',{attrs:{"color":"secondary","dark":"","flat":"","tile":"","dense":"","height":"36px"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(product.icon))]),_c('v-toolbar-title',{staticClass:"cabecera"},[_vm._v(_vm._s(product.type))])],1),_c('v-card-title',{staticClass:"productos pt-2 pb-0"},[_c('p',{staticClass:"flex text-center"},[_vm._v(" "+_vm._s(product.status ? _vm.conv.formatMonto(product.amount, true) : "No posee")+" ")])]),(product.status && product.type === 'Créditos')?_c('v-card-subtitle',{staticClass:"flex text-center"},[_vm._v(" Total Adeudado ")]):(
                product.status &&
                product.type === 'Cuenta capital' &&
                _vm.user.monto_prelacion > 0
              )?_c('v-card-subtitle',{staticClass:"flex text-center"},[_vm._v(" Lista Prelación ")]):_vm._e()],1)],1)}),1)],1),(!_vm.publicidadesSlider)?_c('v-col',{staticClass:"px-12",attrs:{"cols":"12"}},[_c('skeleton-publicidades')],1):_vm._e(),(_vm.publicidadesSlider && _vm.publicidadesSlider.length > 0)?_c('v-col',{staticClass:"px-12",attrs:{"cols":"12"}},[_c('publicidades-fijas')],1):_vm._e()],1),(_vm.dialogoMora)?_c('mora-dialog'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }