import store from "@/store";
import conv from "./conversores";
import { parse, format } from "date-fns";
export default {
  //Retorna si se está trabajando o no en produccion
  prod() {
    return process.env.NODE_ENV === "production";
  },
  //Compara dos fechas
  compareFn(a, b, formato) {
    formato = formato ? formato : "dd/MM/yyyy";
    let aParse = format(
      parse(a, formato, conv.fecha(new Date())),
      "yyyy-MM-dd"
    );
    let bParse = format(
      parse(b, formato, conv.fecha(new Date())),
      "yyyy-MM-dd"
    );
    if (aParse < bParse) {
      return -1;
    }
    if (aParse > bParse) {
      return 1;
    }
    return 0;
  },
  //Compara dos valores numéricos
  compareValue(a, b) {
    let aParse = a.replace("-", "");
    let bParse = b.replace("-", "");
    if (aParse < bParse) {
      return -1;
    }
    if (aParse > bParse) {
      return 1;
    }
    return 0;
  },
  validateEmail(email) {
    email = email.toLowerCase().trim();
    let pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(email);
  },
  fileSrc(file) {
    if (file)
      return (
        process.env.VUE_APP_PUBLIC +
        file.route +
        "/" +
        file._id +
        "." +
        file.dataExt
      );
    else return null;
  },
  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },

  async descargarDocumento(callback) {
    await this.delay(500);
    try {
      await callback();
      store.dispatch("mostrarAlerta", {
        color: "success",
        mensaje: "Descarga realizada correctamente"
      });
    } catch (error) {
      console.error(error);
      store.dispatch("mostrarAlerta", {
        color: "error",
        mensaje:
          "Ha ocurrido un error al descargar el documento, si el error persiste contacte con un administrador"
      });
    }
  },
  isSet(variable) {
    return (
      typeof variable !== "undefined" && variable !== null && variable !== ""
    );
  },
  caracteresRestantes(value, max) {
    if (!value) return max;
    return max - value.length;
  },
  formularioError() {
    store.dispatch("mostrarAlerta", {
      type: "warning",
      message:
        "El formulario que desea enviar presenta errores, por favor revisar su información",
      showClose: true
    });
    const el = document.querySelector(
      ".v-input--error:not(.v-input--disabled):first-of-type"
    );
    el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }
};
