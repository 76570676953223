<template>
  <v-container>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      width="265px"
      min-width="265px"
      mobile-breakpoint="960"
      class="glass-nav nav-props"
      tile
      height="100%"
    >
      <v-img
        :src="require('../../assets/logos/logo_sb.png')"
        max-width="220px"
        class="my-5 mx-auto flex justify-center"
        contain
      ></v-img>
      <!--INICIO MENÚ DE CUENTA-->
      <v-list>
        <v-list-group link color="white" no-action v-if="userLogged" exact>
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="secondary-light">mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="nombre">{{
                nombreUsuario
              }}</v-list-item-title>
              <v-list-item-subtitle>{{ userRut }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:appendIcon>
            <v-icon style="margin-left: 0 !important">mdi-menu-up</v-icon>
          </template>

          <v-list-item
            v-for="child in cuenta.children"
            :key="`cuenta-${child.name}`"
            link
            exact
            :to="`/cuenta/${child.to}`"
          >
            <v-list-item-content>
              <v-list-item-title style="font-size: 0.85rem !important">
                {{ child.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon style="margin: 15px 25px 15px 0 !important">
              <v-icon small color="secondary-light">{{ child.icon }}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list>
      <!-- FIN MENÚ DE CUENTA-->
      <v-divider></v-divider>
      <div v-if="!secciones">
        <v-skeleton-loader
          v-for="i in 7"
          :key="`sklt-menu-${i}`"
          v-bind="attrs"
          type="list-item-avatar"
        ></v-skeleton-loader>
      </div>
      <!-- INICIO MENÚ DE PRINCIPAL -->
      <v-list v-else nav :disabled="userLogged.b_cambiar_clave">
        <div v-for="(item, i) in secciones" :key="i">
          <v-list-item
            :disabled="userLogged.b_cambiar_clave"
            v-if="
              item.children.length == 0 &&
              (item.bit ? userBits[item.bit] : true)
            "
            link
            exact
            :to="{
              name: item.route /*,
              params: item.param ? { [item.paramName]: item.param } : {},*/
            }"
            color="white"
          >
            <v-list-item-icon style="margin: 10px 25px 10px 0 !important">
              <v-badge
                v-if="
                  userCounter[item.route] &&
                  parseInt(userCounter[item.route]) > 0
                "
                :content="userCounter[item.route]"
                offset-x="-20"
                offset-y="15"
                color="secondary"
              >
              </v-badge>
              <v-icon color="secondary-light">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 0.84rem !important">
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>

          <v-list-group
            v-else-if="item.children.length > 0"
            :disabled="userLogged.b_cambiar_clave"
            append-icon="mdi-menu-up"
            no-action
            color="white"
            :group="'/' + item.route"
          >
            <template v-slot:activator>
              <v-list-item v-if="userLogged.b_cambiar_clave" disabled>
                <v-list-item-icon
                  style="margin: 10px 25px 10px -8px !important"
                >
                  <v-icon color="secondary-light">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="font-size: 0.84rem !important">{{
                  item.name
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item-icon
                v-if="!userLogged.b_cambiar_clave"
                style="margin: 15px 25px 15px 0px !important"
              >
                <v-icon color="secondary-light">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                v-if="!userLogged.b_cambiar_clave"
                style="font-size: 0.85rem !important"
                >{{ item.name }}</v-list-item-title
              >
            </template>

            <v-list-item
              v-for="child in item.children"
              :key="`${item.name}-${child.name}`"
              exact-path
              link
              :to="{ name: item.route, params: { product: child.route } }"
            >
              <v-list-item-content>
                <v-list-item-title style="font-size: 0.85rem !important">{{
                  child.name
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon style="margin: 15px 25px 15px 0 !important">
                <v-icon dense color="secondary-light">{{ child.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

      <!-- FIN MENÚ PRINCIPAL -->

      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="logout" block color="error"> Cerrar Sesión </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import auth from "@/auth/auth";
import { formatterRut } from "chilean-formatter";
import conv from "@/services/conversores";
import io from "socket.io-client";

export default {
  data: () => ({
    attrs: {
      class: "mb-0 transparent transparent-sklt"
    },
    cuenta: {
      name: "Ajustes Cuenta",
      icon: "mdi-account-cog",
      to: "cuenta",
      children: [
        {
          name: "Cambiar Clave",
          to: "cambiar-clave",
          icon: "mdi-account-lock"
        } //
      ]
    },
    finalItems: [{ name: "Atención Socios", icon: "mdi-phone" }]
  }),
  computed: {
    ...mapState([
      "userBits",
      "userCounter",
      "secciones",
      "obtenerSecciones",
      "parametros"
    ]),
    userRut() {
      return formatterRut(this.userLogged.rut);
    },
    totalMenu() {
      if (this.secciones) {
        let preArray = this.secciones.map((item) => {
          return { name: item.name, route: item.route };
        });
        preArray.push({ name: "Cuenta", route: "cuenta" });
        return preArray;
      } else return null;
    },
    userLogged() {
      return auth.getUserLogged();
    },
    nombreUsuario() {
      let nombre = this.userLogged.nombre.split(" ")[0];
      let apellido = this.userLogged.apellido_paterno;
      return conv.capitalizeString(nombre + " " + apellido);
    },
    ...mapGetters({
      drawerTest: "drawer"
    }),
    drawer: {
      get() {
        return this.drawerTest;
      },
      set(state) {
        this.switchDrawer(state);
        return state;
      }
    }
  },
  methods: {
    ...mapMutations(["cambiarRuta"]),
    ...mapActions([
      "switchDrawer",
      "close_timeout",
      "setSecciones",
      "setParametros"
    ]),
    logout() {
      this.close_timeout();
      auth.cerrarSesion();
    }
  },
  mounted() {
    this.socket = io(process.env.VUE_APP_API_BASE); // Escucha eventos desde el servidor
    console.log("Conexión Establecida");
    this.socket.on("updatePortalMenu", async () => {
      console.log("updatePortalMenu");
      this.setSecciones();
      //if (this.parametros.mantencion) this.$router.push("/mantencion");
    });
    this.socket.on("updatePortalParam", async () => {
      console.log("updatePortalParam");

      await this.setParametros();
      if (this.parametros.mantencion) {
        this.$router.push("/mantencion");
        return;
      }
      let meta = this.$route.meta?.requiresParam;
      if (this.$route.meta.requiresParam && !this.parametros[meta]) {
        this.$router.push("/");
      }
    });
  },
  beforeDestroy() {
    console.log("Conexión Terminada");
    if (this.socket) {
      this.socket.disconnect();
    }
  }
};
</script>
<style scoped lang="css">
.transparente {
  background-color: transparent;
}

.nombre {
  white-space: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}
</style>
