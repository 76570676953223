<template>
  <div>
    <div v-if="loadingNormal" :class="classArr">
      <div id="loadingLogoCoonfia">
        <div class="loading c1"></div>
        <div class="loading c2"></div>
      </div>
    </div>

    <div v-if="!loadingNormal">
      <div id="loadingLogoCoonfia">
        <div class="loading c1"></div>
        <div class="loading c2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressSpiner",
  props: {
    loadingNormal: {
      type: Boolean,
      default: true
    },
    overlay: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classArr() {
      let classArr = [];
      if (this.overlay) classArr.push("bg-overlay");
      if (this.fixed) classArr.push("loading-overlay-fixed");
      else if (this.absolute) classArr.push("loading-overlay-absolute");
      else classArr.push("loading-overlay-relative");
      return classArr;
    }
  }
};
</script>

<style scoped>
.loading-overlay-fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  /* Color de fondo semi-opaco oscuro */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Asegura que esté encima de otros elementos */
}
.loading-overlay-absolute {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 9999;
  /* Asegura que esté encima de otros elementos */
}
.loading-overlay-relative {
  position: relative;
  z-index: 9999;
  /* Asegura que esté encima de otros elementos */
}

.bg-overlay {
  background-color: rgba(205, 205, 205, 0.5);
}

#loadingLogoCoonfia {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.loading {
  margin: -11px;
  width: 40px;
  height: 40px;
  border: 8px solid transparent;
  border-top-color: #00d0da;
  border-left-color: #00d0da;
  border-bottom-color: #00d0da;
  border-radius: 50%;
}

.c1,
.c2 {
  animation: spin 0.7s infinite;
}

.c1 {
  rotate: 45deg;
}

.c2 {
  rotate: 230deg;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
