import { parseISO, isDate, format, parse } from "date-fns";
import { es } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";
export default {
  fecha(fecha) {
    if (!isDate(fecha)) {
      fecha = parseISO(fecha); // Ya es un objeto Date, no es necesario parseISO
    }
    return utcToZonedTime(fecha);
  },
  formatMonto(monto, currency) {
    let intMonto = parseInt(parseFloat(monto));
    if (currency)
      return Intl.NumberFormat("es-CL", {
        currency: "CLP",
        style: "currency"
      }).format(Math.abs(intMonto));
    else
      return Intl.NumberFormat("es-CL", { currency: "CLP" }).format(
        Math.abs(intMonto)
      );
  },
  formatPorcentaje(valor) {
    valor = valor.replace(/,/g, ".");
    let num = parseFloat(valor);
    return num.toFixed(3) + "%";
  },
  formatPorcentaje2(valor) {
    let num = parseFloat(valor) * 100;
    return num.toFixed(2) + "%";
  },
  formatDias(valor) {
    return valor == 1 ? valor + " día" : valor + " días";
  },
  formatFecha(fecha) {
    return format(this.fecha(fecha), "dd/MM/yyyy");
  },
  formatFecha2(fecha, srcFormat) {
    let parsedDate = parse(fecha, srcFormat, this.fecha(new Date()));
    return format(parsedDate, "dd/MM/yyyy");
  },
  formatFechaPalabras(fecha) {
    if (!fecha) fecha = new Date();
    return format(this.fecha(fecha), "dd 'de' MMMM, yyyy", { locale: es });
  },
  fechaNombreDocs() {
    return format(this.fecha(new Date()), "ddMMyyHHmmss");
  },
  formatFechaHora(fecha, srcFormat) {
    const parsedDate = parse(fecha, srcFormat, this.fecha(new Date()));
    return format(parsedDate, "dd/MM/yyyy HH:mm");
  },
  capitalizeString(texto) {
    if (!texto) return "";

    let formatText = texto.replace(/\s+/g, " ").trim();
    let arr = formatText.split(" ");

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    return arr.join(" ");
  },
  capitalizeFirtsString(texto) {
    return texto.charAt(0).toUpperCase() + texto.slice(1);
  },
  desmonetizar(monto) {
    return monto.replace("$", "").split(".").join("");
  },
  desmonetizarInt(monto) {
    let noFormat = monto.replace("$", "").split(".").join("");
    if (noFormat) return parseInt(noFormat);
    else return 0;
  },
  nombreCredito(texto) {
    texto = texto.toLowerCase();
    let sinId = texto.split(" - ")[1];
    if (sinId) texto = sinId;
    if (!texto.startsWith("credito") && !texto.startsWith("crédito"))
      texto = "Crédito " + texto;

    return this.capitalizeString(texto);
  }
};
