<template>
  <div>
    <v-app-bar app dense height="40" dark tile class="glass-top-nav">
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click.stop="switchDrawer(true)"
      ></v-app-bar-nav-icon>
      <v-icon x-large>mdi-menu-right</v-icon>
      <v-toolbar-title class="mx-2 cabecera">
        {{ titulo }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="parametros.mostrar_contactanos"
        text
        rounded
        :to="{ name: 'contacto' }"
      >
        <v-icon color="white" left>mdi-card-account-mail</v-icon>
        Contáctanos
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: function () {
    return {};
  },
  methods: {
    ...mapActions(["switchDrawer"])
  },
  computed: {
    ...mapState(["parametros"]),
    titulo() {
      return this.$route.meta?.titulo;
    }
  },
  mounted() {}
};
</script>

<style scoped lang="css">
.cabecera {
  font-weight: 300;
  font-size: 25px;
  line-height: 25px;
}
</style>
