<template>
  <v-dialog
    :value="dialog"
    width="600px"
    transition="dialog-top-transition"
    @click:outside="$emit('cerrar')"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    class="p-margin"
  >
    <v-card height="100%">
      <v-toolbar color="primary" dark class="text-center" height="40" flat>
        <div class="titulo titulo-local text-center" style="width: 100%">
          {{ item.title }}
        </div>
        <v-btn @click="$emit('cerrar')" icon fab absolute right tile>
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-img
        v-if="item.image"
        :src="fn.fileSrc(item.image)"
        width="100%"
        min-height="0"
        contain
      ></v-img>
      <v-card-text
        v-if="item.description"
        class="lato pt-2"
        style="font-size: 16px"
      >
        <div class="ql-editor" v-sane-html="item.description"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import fn from "@/services/funciones";

export default {
  props: {
    item: Object,
    dialog: Boolean
  },
  data() {
    return {
      fn
    };
  }
};
</script>

<style scoped>
.titulo-local {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.7rem;
}
</style>
