import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../views/InicioView.vue";
import auth from "../auth/auth";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "crear-contrasena/:id_cliente",
        name: "crear_contrasena",
        component: () => import("../components/login/RecuperarClave.vue")
      }
    ]
  },
  {
    path: "/inicio",
    name: "ingresa",
    component: Inicio,
    meta: {
      titulo: "Inicio",
      requiresAuth: true,
      requireMenu: true
    }
  },
  {
    path: "/productos/:product",
    name: "productos",
    component: () => import("../views/ProductosView.vue"),
    meta: {
      requiresAuth: true,
      requireMenu: true
    }
  },
  {
    path: "/proximos-pagos",
    name: "proximos-pagos",
    component: () => import("../views/ProximosPagosView.vue"),
    meta: {
      titulo: "Próximos Pagos",
      requiresAuth: true,
      requireMenu: true
    }
  },
  {
    path: "/cuenta/:ajuste",
    name: "cuenta",
    component: () => import("../views/CuentaView.vue"),
    meta: {
      titulo: "Cuenta",
      requiresAuth: true
    }
  },
  {
    path: "/ultimas-novedades",
    name: "ultimas-novedades",
    component: () => import("../views/NovedadesView.vue"),
    meta: {
      titulo: "Últimas Novedades",
      requiresAuth: true,
      requireMenu: true
    }
  },
  {
    path: "/descarga-documentos",
    name: "descarga-documentos",
    component: () => import("../views/DescargaDocumentosView.vue"),
    meta: {
      titulo: "Descarga de Documentos",
      requiresAuth: true,
      requireMenu: true
    }
  },
  {
    path: "/cuotas-participacion",
    name: "cuotas-participacion",
    component: () => import("../views/CuotasParticipacionView.vue"),
    meta: {
      titulo: "Cuotas de Participación",
      requiresAuth: true,
      requireMenu: true,
      requiresBit: "b_socio"
    }
  },
  {
    path: "/documentos-firmar",
    name: "documentos-firmar",
    component: () => import("../views/DocumentosFirmarView.vue"),
    meta: {
      titulo: "Documentos para Firmar",
      requiresAuth: true,
      requireMenu: true
    }
  },
  {
    path: "/contactanos",
    name: "contacto",
    component: () => import("../views/ContactoView.vue"),
    meta: {
      titulo: "Formularios",
      requiresAuth: true,
      requiresParam: "mostrar_contactanos"
      // requireMenu: true
    }
  },
  {
    path: "/mantencion",
    name: "mantencion",
    component: () => import("../views/MantencionView.vue")
  },
  {
    path: "/",
    redirect: "/inicio"
  },
  {
    path: "*",
    redirect: "/inicio"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  //Lógica que se ejecuta antes de renderizar la ruta a la vista respectiva
  //Primero se debe validar si la ruta requiere autenticación
  if (to.name === "mantencion") {
    if (store.state.parametros.mantencion) next();
    else next("/");
    return;
  } else if (to.name !== "mantencion" && store.state.parametros.mantencion) {
    next("/mantencion");
    return;
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      //Si la ruta requiere autenticación se valida si el usuario está autenticado
      if (auth.isAuthenticated()) {
        store.dispatch("updateCounter", {
          accion: 2,
          clien_s_id: auth.getUserLogged().id_cliente
        });

        //Si el usuario está autenticado se valida si el usuario debe cambiar su clave
        let cambio_clave = auth.getUserLogged().b_cambiar_clave;
        if (cambio_clave) {
          if (to.path === "/cuenta/cambiar-clave") next();
          else next("/cuenta/cambiar-clave");
          return;
        }
        //Si el usuario no debe cambiar su clave se realizan otras validaciones según la ruta a la que desee acceder y sus precondiciones
        else {
          if (to.path === "/contactanos") {
            if (store.state.parametros.mostrar_contactanos) return next();
            else return next("/");
          }
          if (to.matched.some((record) => record.meta.requireMenu)) {
            let route = to.name;
            if (store.state.secciones.some((item) => item.route === route)) {
              if (to.matched.some((record) => record.meta.requiresBit)) {
                let bit = to.meta.requiresBit;

                if (store.state.userBits[bit]) {
                  next();
                } else {
                  next("/");
                }
              } else {
                if (to.name === "documentos-firmar") {
                  let firma = to.query.firma;
                  if (firma) {
                    if (firma === "ok") localStorage.setItem("firma", "1");
                    else if (firma === "traza")
                      localStorage.setItem("firma", "2");
                    else localStorage.setItem("firma", "0");

                    next({
                      path: "/documentos-firmar",
                      replace: true
                    });
                  } else {
                    next();
                    return;
                  }
                } else {
                  next();
                  return;
                }
              }
            } else {
              next("/");
            }
          }

          //Si la ruta es de pago web se valida si se está accediendo directamente o es retorno de transbank para determinar que mostrar
          // else if (to.name === "pagoweb") {
          //   if (to.query.token_ws) {
          //     localStorage.setItem("pay_token", to.query.token_ws);
          //     localStorage.setItem("pay_type", "TBK");
          //     next({
          //       path: "/pago-web",
          //       replace: true
          //     });
          //     return;
          //   } else if (to.query.TBK_ID_SESION && to.query.TBK_ORDEN_COMPRA) {
          //     let cancel_pay = {
          //       TBK_ID_SESION: to.query.TBK_ID_SESION,
          //       TBK_ORDEN_COMPRA: to.query.TBK_ORDEN_COMPRA
          //     };
          //     if (to.query.TBK_TOKEN) cancel_pay.TBK_TOKEN = to.query.TBK_TOKEN;

          //     localStorage.setItem("cancel_pay", JSON.stringify(cancel_pay));
          //     next({
          //       path: "/pago-web",
          //       replace: true
          //     });
          //     return;
          //   } else if (to.query.token_fl && to.query.type) {
          //     localStorage.setItem("pay_token", to.query.token_fl);
          //     localStorage.setItem("pay_type", to.query.type);
          //     localStorage.setItem("id_pago", to.query.id_pago);
          //     next({
          //       path: "/pago-web",
          //       replace: true
          //     });
          //     return;
          //   }
          //   next();
          //   return;
          // }
          //Si la ruta es de simulador de créditos se valida si el usuario tiene créditos por firmar

          //Si la ruta no tiene que validar condiciones especiales se redirige a la ruta solicitada
          else {
            next();
            return;
          }
        }
      }
      //Si el usuario no está autenticado se redirige a la ruta de login
      else {
        next("/login");
      }
    }
    //Si la ruta no requiere autenticación se valida si el usuario está autenticado para redirigirlo a la ruta de inicio
    else {
      if (auth.isAuthenticated()) {
        next("/ingresa");
        return;
      } else {
        next();
      }
    }
  }
});

export default router;
