<template>
  <v-container class="raleway py-0" id="sliderCarouselCon">
    <v-toolbar
      color="transparent"
      dark
      flat
      tile
      height="36px"
      class="d-flex justify-center"
    >
      <v-toolbar-title class="cabecera-info">Informaciones</v-toolbar-title>
    </v-toolbar>
    <VueSlickCarousel
      ref="sliderCarousel"
      class="sliderCarousel"
      v-bind="settings"
    >
      <div
        v-for="(item, i) in publicidadesSlider"
        :key="item._id"
        class="px-1 pb-4"
      >
        <v-toolbar
          color="secondary"
          dark
          flat
          tile
          height="25px"
          class="text-center"
        >
          <div class="cabecera text-center" style="width: 100%">
            {{ item.title }}
          </div>
          <v-btn
            :id="`expandir-publicidad-${item._id}`"
            @click="expandirPublicidad(item)"
            icon
            fab
            absolute
            right
            tile
            small
          >
            <v-icon size="20">mdi-magnify-expand</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card
          @mouseover="hover = i"
          @mouseleave="hover = null"
          outlined
          tile
          class="lato pa-0"
          :elevation="hover == i ? 6 : 2"
          max-height="item.image? '210px' : '150px'"
          height="100%"
        >
          <div class="full-height-2" v-if="item.image">
            <v-img
              :src="fn.fileSrc(item.image)"
              :aspect-ratio="4 / 2"
              class="align-self-center"
              cover
            >
            </v-img>
          </div>
          <v-list-item v-else class="pa-0 full-height-2">
            <v-list-item-content class="pb-2 pt-1 px-2">
              <v-list-item-subtitle
                v-if="item.description && !item.image"
                class="font-weight-regular desc-text"
              >
                <div
                  class="ql-editor trunc-text"
                  v-sane-html="item.description"
                ></div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-fab-transition>
            <div
              v-if="item.image && item.description && hover == i"
              class="d-flex transition-fast-in-fast-out primary v-card--reveal full-height-2"
            >
              <v-card-text class="p-margin">
                <div
                  :style="{
                    'font-size': fontSize,
                    'line-height': lineHeight
                  }"
                  class="white--text trunc-text lato ql-editor"
                  v-sane-html="item.description"
                />
              </v-card-text>
            </div>
          </v-fab-transition>
        </v-card>
      </div>
    </VueSlickCarousel>
    <dialogo-expandir
      v-if="dialogExpand"
      :dialog="dialogExpand"
      :item="itemExpand"
      @cerrar="closeExpand()"
    ></dialogo-expandir>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import DialogoExpandir from "./DialogoExpandir.vue";
import fn from "@/services/funciones";

export default {
  components: {
    DialogoExpandir,
    VueSlickCarousel
  },
  data() {
    return {
      fn,
      dialogExpand: false,
      itemExpand: null,
      hover: null,
      settings: {
        dots: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 10000,
        focusOnSelect: true,
        pauseOnHover: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      },
      altura: "180px"
    };
  },
  computed: {
    lines() {
      let height = document.querySelector(
        ".card-publicidad-test"
      )?.offsetHeight;
      if (!height) return "5 !important";
      let lineClamp = parseInt((height - 10) / 17) - 1;
      return lineClamp + " !important";
    },
    ...mapState(["publicidadesSlider"]),
    mobile() {
      return this.$vuetify.breakpoint.xsOnly ? true : false;
    },
    fontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "0.9rem";
        case "sm":
          return "1rem";
        default:
          return "1rem";
      }
    },
    lineHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "4vw";
        case "sm":
          return "20px";
        default:
          return "2.5vh";
      }
    }
  },
  methods: {
    imgSrc(image) {
      return "data:" + image.dataType + ";base64," + image.base64;
    },
    expandirPublicidad(item) {
      this.dialogExpand = true;
      this.itemExpand = item;
    },
    closeExpand() {
      this.dialogExpand = false;
      this.itemExpand = null;
    }
  },
  mounted() {}
};
</script>
<style scoped>
:deep(.slick-arrow:before) {
  color: var(--v-primary-base);
  opacity: 1;
}
:deep(.slick-slide) {
  height: auto !important;
}
:deep(.v-list-item__content) {
  align-self: flex-start !important;
}
:deep(.slick-track) {
  display: flex !important;
}
:deep(.slick-slide) > div:first-of-type {
  height: 100% !important;
}
:deep(.slick-slider) .v-image__image {
  background-repeat: repeat !important;
}
:deep(.slick-slider) .v-toolbar__content {
  padding-right: 0px !important;
}
:deep(.slick-slider) .full-height {
  height: calc(100% - 25px) !important;
}
:deep(.slick-slider) .full-height-2 {
  height: 100% !important;
}
:deep(.slick-slider) .desc-text {
  white-space: normal !important;
}
:deep(.slick-slider) .list-height {
  height: 100% !important;
}
.cite {
  font-size: 17px;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.cabecera {
  color: white;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.2rem;
}

.cabecera-info {
  color: black;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.5rem;
}
</style>
