import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import { vMaska } from "maska";
import Vidle from "v-idle";
import DOMPurify from "dompurify";

import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "./plugins";

DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener");
  }
});

Vue.config.productionTip = false;
store.dispatch("initData").then(() => {
  Vue.directive("sane-html", (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  });
  Vue.directive("maska", (el, binding) => {
    if (el.querySelector("input").value) vMaska(el, binding);
  });
  Vue.use(Vidle);
  Vue.use(VueMask);

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App)
  }).$mount("#app");
});
