<template>
  <div>
    <v-toolbar
      color="transparent"
      flat
      tile
      height="36px"
      class="d-flex justify-center subtitle-1"
    >
      <div>Cargando Informativos...</div>
    </v-toolbar>
    <v-row justify="center" class="mx-2">
      <v-col v-for="i in 3" :key="i" cols="4">
        <v-skeleton-loader
          max-height="130px"
          min-width="100%"
          elevation="2"
          type="image"
          loading
        ></v-skeleton-loader>
        <v-skeleton-loader
          width="30%"
          class="mt-2"
          type="text"
          loading
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
