import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import router from "../router";
import store from "@/store";

const URL_API = process.env.VUE_APP_API_URL + "portal-privado/auth/";
const URL_API_CLI = process.env.VUE_APP_API_URL + "portal-privado/cliente/";

export default {
  login(rut, password) {
    let data = {
      usuario: rut,
      clave: password,
    };
    return axios.post(URL_API + "login", data);
  },
  setUserLogged(userLogged) {
    let expireTime = new Date(new Date().getTime() + 14.95 * 60 * 1000);
    sessionStorage.setItem("userImageLR", userLogged.imagen_b64);
    delete userLogged.imagen_b64;
    let usuarioEncriptado = CryptoJS.AES.encrypt(
      JSON.stringify(userLogged),
      process.env.VUE_APP_CRYPT_KEY
    ).toString();
    Cookies.set("userLoggedLR", usuarioEncriptado, {
      expires: expireTime,
    });
    Cookies.set("userLoggedExpired", expireTime);
  },

  extenderCookie(min) {
    let usuarioEncriptado = Cookies.get("userLoggedLR");
    Cookies.remove("userLoggedLR");

    let currentExpire = Cookies.get("userLoggedExpired");
    let newExpireTime = new Date(
      new Date(currentExpire).getTime() + min * 60 * 1000
    );
    Cookies.set("userLoggedLR", usuarioEncriptado, {
      expires: newExpireTime,
    });
    Cookies.set("userLoggedExpired", newExpireTime);
  },
  getUserLogged() {
    let usuarioEncriptado = Cookies.get("userLoggedLR");
    let usuario = CryptoJS.AES.decrypt(
      usuarioEncriptado,
      process.env.VUE_APP_CRYPT_KEY
    ).toString(CryptoJS.enc.Utf8);
    return JSON.parse(usuario);
  },
  async cerrarSesion(redirect = true) {
    Cookies.remove("userLoggedExpired");
    Cookies.remove("userLoggedLR");
    if (redirect) await router.push("/login");
    await store.dispatch("cerrarSesion");
  },
  isAuthenticated() {
    return Cookies.get("userLoggedLR") !== undefined;
  },
  isExpireSet() {
    return Cookies.get("userLoggedExpired") !== undefined;
  },

  userInfo(id_cliente, accion) {
    return axios.get(URL_API_CLI + `info_cliente/${id_cliente}/${accion || 1}`);
  },
  info_campanias(id_cliente) {
    return axios.get(URL_API_CLI + "info_campanias/" + id_cliente);
  },
  generarClave(data) {
    return axios.post(URL_API + "recuperar_clave", data);
  },
  cambiarClave(data) {
    return axios.post(URL_API + "cambiar_clave", data);
  },
  bitsCliente(form) {
    let data = {
      clien_s_id: form.clien_s_id,
      accion: form.accion,
    };
    return axios.post(URL_API_CLI + "bits_cliente", data);
  },
  bitsGlobales() {
    let data = {
      accion: 3,
    };
    return axios.post(URL_API_CLI + "bits_cliente", data);
  },
};
