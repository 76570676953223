<template>
  <v-overlay v-model="showOverlay" class="align-center justify-center">
    <progress-spiner fixed />
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";
import ProgressSpiner from "./ProgressSpiner.vue";
export default {
  components: {
    ProgressSpiner
  },
  computed: {
    ...mapState(["loadingState"]),
    showOverlay() {
      return this.loadingState;
    }
  },
  mounted() {}
};
</script>

<style scoped></style>
