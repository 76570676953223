import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import mantenedor from "@/services/mantenedor";
import axios from "axios";
import auth from "@/auth/auth";
import router from "@/router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    rutaActual: "",
    snackbar: {
      mostrar: false,
      mensaje: "",
      color: "success",
      icon: "",
    },
    expireTimeout: null,
    drawer: true,
    dialogoMora: false,
    dialogoCampania: false,
    dialogoClave: false,
    userBits: {
      b_creditos_firmar: false,
      b_pago: false,
      b_pago_credito: false,
      b_socio: true,
    },
    loadingGlobalBits: true,
    parametros: {
      mantencion: false,
      popup_clave: false,
      mostrar_contactanos: false,
    },
    userCounter: {},
    setCounter: false,
    publicidadesDialogo: null,
    publicidadesSlider: null,
    informativos: null,
    dialogoPublicidades: false,
    secciones: null,
    dialogoFirma: false,
    loadingState: true,
    tienePermiso: null,
  },
  getters: {
    drawer: (state) => {
      return state.drawer;
    },
  },
  mutations: {
    cambiarRuta(state, ruta) {
      state.rutaActual = ruta;
    },
    SET_SNACKBAR(state, payload) {
      state.snackbar = payload;
    },
    CLOSE_SNACKBAR(state) {
      state.snackbar.mostrar = false;
    },
    SET_DRAWER(state, value) {
      state.drawer = value;
    },
    SET_DIALOGO_MORA(state, value) {
      state.dialogoMora = value;
    },
    SET_DIALOGO_CAMPANIA(state, value) {
      state.dialogoCampania = value;
    },
    SET_TIMEOUT(state, remaining) {
      state.expireTimeout = setTimeout(function () {
        alert(
          "Su sesión ha expirado, si quiere seguir utilizando el portal ingrese nuevamente"
        );
        auth.cerrarSesion();
      }, remaining);
    },
    CLOSE_TIMEOUT(state) {
      clearTimeout(state.expireTimeout);
    },
    SET_BITS(state, value) {
      state.userBits = value;
    },
    SET_PARAMETROS(state, value) {
      state.parametros = value;
    },

    SET_COUNTERS(state, value) {
      state.userCounter = value;
      state.setCounter = true;
    },
    SET_INFORMATIVOS(state, value) {
      state.informativos = value;
    },
    SET_PUBLICIDADES(state, value) {
      if (value.dialog) state.publicidadesDialogo = value.data;
      else state.publicidadesSlider = value.data;
    },
    SET_DIALOGO_PUBLICIDADES(state, value) {
      state.dialogoPublicidades = value;
    },
    SET_DIALOGO_CLAVE(state, value) {
      state.dialogoClave = value;
    },
    CLEAN_PUBLICIDADES(state) {
      state.publicidadesDialogo = null;
      state.publicidadesSlider = null;
    },
    SET_SECCIONES(state, value) {
      state.secciones = value;
    },
    SET_DIALOGO_FIRMA(state, value) {
      state.dialogoFirma = value;
    },
    SET_LOADING_STATE(state, value) {
      state.loadingState = value;
    },
    RESET_INITIAL(state) {
      state.dialogoMora = false;
      state.dialogoCampania = false;
      state.userBits = {
        b_creditos_firmar: false,
        b_pago: false,
        b_pago_credito: false,
        b_socio: true,
      };
      state.globalBits = {
        bit_mantencion: false,
      };
      state.userCounter = {};
      state.setCounter = false;
      state.secciones = null;
      state.dialogoFirma = false;
      state.loadingState = false;
    },
  },
  actions: {
    mostrarAlerta({ commit }, payload) {
      if (payload.color === "success") {
        payload.icon = "mdi-check-circle-outline";
      } else if (payload.color === "warning") {
        payload.icon = "mdi-alert-outline";
      } else if (payload.color === "error") {
        payload.icon = "mdi-alert-octagon-outline";
      } else if (payload.color === "info") {
        payload.icon = "alert-circle-outline";
      }

      commit("SET_SNACKBAR", {
        mostrar: true,
        color: payload.color,
        mensaje: payload.mensaje,
        icon: payload.icon,
      });
    },
    cerrarAlerta({ commit }) {
      commit("CLOSE_SNACKBAR");
    },

    switchDrawer({ commit }, value) {
      commit("SET_DRAWER", value);
    },
    mostrarDialogoMora({ commit }, value) {
      commit("SET_DIALOGO_MORA", value);
    },
    mostrarDialogoCampania({ commit }, value) {
      commit("SET_DIALOGO_CAMPANIA", value);
    },
    set_timeout({ commit }) {
      let current = new Date().getTime();
      let expires = new Date(Cookies.get("userLoggedExpired"));
      let remaining = expires.getTime() - current;
      console.log(
        "Expiración: ",
        expires.getHours() +
          ":" +
          (expires.getMinutes() < 10
            ? "0" + expires.getMinutes()
            : expires.getMinutes())
      );
      commit("SET_TIMEOUT", remaining);
    },
    close_timeout({ commit }) {
      commit("CLOSE_TIMEOUT");
    },
    async updateBits({ commit }, value) {
      if (process.env.NODE_ENV === "development") {
        console.log("Actualizando bits");
      }
      await auth
        .bitsCliente(value)
        .then((response) => {
          let bits = response.data;
          commit("SET_BITS", bits);
        })
        .catch((error) => console.error(error));
    },
    async updateCounter({ commit }, value) {
      if (process.env.NODE_ENV === "development") {
        console.log("Actualizando contadores");
      }
      await auth
        .bitsCliente(value)
        .then((response) => {
          let counters = response.data;
          commit("SET_COUNTERS", counters);
        })
        .catch((error) => console.error(error));
    },
    mostrarDialogoPublicidad({ commit }, value) {
      commit("SET_DIALOGO_PUBLICIDADES", value);
    },
    mostrarDialogoClave({ commit }, value) {
      commit("SET_DIALOGO_CLAVE", value);
    },

    async setInformativos({ commit }) {
      await mantenedor
        .obtenerInformativos()
        .then((res) => {
          const data = res.data;
          // necesito que si muestre el informativo 1 si tiene tienePermis os 1 y los demas
          // pero si no tiene permisos 1 que muestre los restantes
          axios
            .get(
              process.env.VUE_APP_API_URL +
                `portal-privado/cliente/validacion-asamblea-cliente/${
                  auth.getUserLogged().id_cliente
                }`
            )
            .then((res) => {
              const tienePermiso = res.data;
              if (tienePermiso == 0) {
                const dataFiltrada = data.filter(
                  (i) => i._id != "6679c7b357f7254ae082a971"
                );
                commit("SET_INFORMATIVOS", dataFiltrada);
              }

              if (tienePermiso == 1) {
                const dataFiltrada = data.filter((i) => i._id);
                commit("SET_INFORMATIVOS", dataFiltrada);
              }
            });
        })
        .catch((error) => console.error(error));
    },
    async setPublicidades({ commit }, data) {
      await mantenedor
        .obtenerPublicidades(data.user, data.mostrar)
        .then((res) => {
          commit("SET_PUBLICIDADES", { data: res.data, dialog: data.mostrar });
          if (data.mostrar && res.data.length > 0)
            commit("SET_DIALOGO_PUBLICIDADES", true);
        })
        .catch((error) => console.error(error));
    },
    async setSecciones({ commit }) {
      await mantenedor
        .obtenerSecciones()
        .then((res) => {
          commit("SET_SECCIONES", res.data);
        })
        .catch((error) => console.error(error));
    },

    async setParametros({ commit }) {
      commit("SET_LOADING_STATE", true);
      await mantenedor
        .obtenerParametros()
        .then((res) => {
          commit("SET_PARAMETROS", res.data);
          if (res.data.popup_clave) commit("SET_DIALOGO_CLAVE", true);
        })
        .catch((error) => console.error(error));
      commit("SET_LOADING_STATE", false);
    },

    mostrarDialogoFirma({ commit }, value) {
      commit("SET_DIALOGO_FIRMA", value);
    },
    setLoadingState({ commit }, value) {
      commit("SET_LOADING_STATE", value);
    },
    limpiarPublicidades({ commit }) {
      commit("SET_DIALOGO_PUBLICIDADES", false);
      commit("CLEAN_PUBLICIDADES");
      commit("SET_INFORMATIVOS", null);
    },
    async cerrarSesion({ commit, dispatch }) {
      commit("RESET_INITIAL");
      await dispatch("limpiarPublicidades");
    },
    async initData({ dispatch }) {
      try {
        let promises = [dispatch("setSecciones"), dispatch("setParametros")];
        if (auth.isAuthenticated()) {
          let data = {
            accion: 1,
            clien_s_id: auth.getUserLogged().id_cliente,
          };
          promises.push(dispatch("updateBits", data));
        }
        await Promise.all(promises);
      } catch (error) {
        dispatch("close_timeout");
        Cookies.remove("userLoggedExpired");
        Cookies.remove("userLoggedLR");
        await Vue.nextTick(); // Ensure the DOM is updated before navigating
        await router.push("/login");
  
      }
    },
  },
  modules: {},
});
