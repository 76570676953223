<template>
  <v-row align-content="center" justify="center" class="pb-4">
    <v-col class="text-subtitle-1 text-center" cols="12">
      {{ text }}
    </v-col>
    <v-col cols="10">
      <progress-spiner />
    </v-col>
  </v-row>
</template>
<script>
import ProgressSpiner from "./ProgressSpiner.vue";
export default {
  components: {
    ProgressSpiner
  },
  props: {
    text: {
      type: String,
      default: "Cargando Documentos"
    }
  },
  data() {
    return {
      //
    };
  }
};
</script>
